import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"


const Page = ({data}) => {
  
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  const mailhtml = mailblock(lang)

  return (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("お問い合わせ")} lang={lang} />
      <ContentLayout lang={lang}>
        <h1>{t("お問い合わせ")}</h1>
        <p className="imgbox tel">
          {t("国立研究開発法人理化学研究所 筑波事業所 研究支援部 総務課")}<br />
          {t("〒305-0074 茨城県つくば市高野台3-1-1")}<br />
          {t("TEL: 029-836-9111 (代表)")}<br />
          {t("mailaddress")}
        </p>

        {mailhtml}

        <h2>{t("バイオリソースに関するお問い合わせ")}</h2>
        <p className="txt-center">
          
          <External 
            href={"https://"+t("web.brc.riken.jp/ja/inquirylist")}
            btn="2"
            >
            {t("お問い合わせ（理化学研究所バイオリソース研究センター）")}
          </External>
        </p>
      </ContentLayout>
    </Layout>
  )
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`

function mailblock(language) {
  if(language==='en'){
    return (
      <></>
    )
  }else{
    
    return (
      <>
        <h2 className="headline2-icon mail">メール</h2>
        <DataList>
          <DataListRow title="見学について">kengaku-tsukuba [at] ml.riken.jp</DataListRow>
          <DataListRow title="その他のお問い合わせ">contact-tsukuba [at] ml.riken.jp</DataListRow>
        </DataList>
        <p className="imgbox mail">メールアドレスの[at]は@に置き換えてください。</p>
      </>
    )
  }
}